
import './App.css';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import {useMemo} from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Home from './components/Home';
import Contact from './components/Contact';
import AboutUs from './components/AboutUs';
import Faq from './components/Faq';
import BlogDetails from './components/BlogDetails';
// BlogsListing
import BlogsListing from './components/BlogsListing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatPopup from './components/chat/ChatPopup';
// import Loader from './common/Loader';
function App() {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route exact path="/contact-us" element={<Contact />} ></Route>
                <Route exact path="/about-us" element={<AboutUs />} ></Route>
                <Route exact path="/faq" element={<Faq />} ></Route>
                <Route exact path="/blog-details/:id" element={<BlogDetails />} ></Route>
                <Route exact path="/blogs-listing" element={<BlogsListing />} ></Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <ChatPopup />
            <Footer />

            <ToastContainer position="top-center" />
        </div>

    );


}

export default App;

