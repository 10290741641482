import {Link} from "react-router-dom";

function Footer() {
    const currentYear = new Date().getFullYear();
    const baseUrl = window.location.hostname;
    return (
        <>
      
            <footer className="bg-dark py-4 mt-auto">
                <div className="container px-5">
                    <div className="row align-items-center justify-content-between flex-column flex-sm-row">
                        <div className="col-auto">  <div className="small m-0 text-white">Copyright ©{baseUrl} {currentYear} </div> </div>
                        <div className="col-auto">
                            <Link className="link-light small" to="/">Privacy</Link>
                            <span className="text-white mx-1">·</span>
                            <Link className="link-light small" to="/">Terms</Link>
                            <span className="text-white mx-1">·</span>
                            <Link className="link-light small" to="/contact-us">Contact</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;