import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import common from '../config/common';
import Loader from './common/Loader';
export default function BlogDetails() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [blog, setBlog] = useState(null);
    useEffect(() => {
        setLoading(true);
        common.apiCalling("api/blogs/blog-details", "post", { blog_id: id}).then((res) => {
            setLoading(false);
            // setList({ loading: false });
            // common.print("api res")
            // common.print(res)
            if (res.code === 1) {
                setBlog(res.data)
                // toast.success(res.message);        
                // common.print(res.data)     
            } else {
                // toast.error(res.message)
                setBlog(null)
            }
        });
    }, [id])
    return (
        // <div>BlogDetails

        //       <p>ID: {id}</p>
        // </div>
        <div>
            <Loader loading={loading} />            
            <section className="py-5">
                <div className="container px-5 my-5">
                    <div className="row gx-5">
                        <div className="col-lg-3">
                            <div className="d-flex align-items-center mt-lg-5 mb-4">
                                {/* <img className="img-fluid rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /> */}
                                <div className="ms-3">
                                    <div className="fw-bold">{blog && blog.written_by}</div>
                                    <div className="text-muted">Blogger</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">                            
                            <article>                                
                                <header className="mb-4">                                    
                                    <h1 className="fw-bolder mb-1">{blog && blog.title}</h1>
                                    
                                    <div className="text-muted fst-italic mb-2">{blog && blog.created_at}</div>
                                    
                                    <a className="badge bg-secondary text-decoration-none link-light" href="#!">{blog && blog.category}</a>
                                   
                                </header>
                                
                                <figure className="mb-4"><img className="img-fluid rounded" src={blog && blog.blog_image} alt="..." /></figure>
                                
                                <section className="mb-5">
                                    <p className="fs-5 mb-4">{blog && blog.description}</p>
                                </section>
                            </article>
                            
                            {/* <section>
                                <div className="card bg-light">
                                    <div className="card-body">
                                        
                                        <form className="mb-4"><textarea className="form-control" rows={3} placeholder="Join the discussion and leave a comment!" defaultValue={""} /></form>
                                        
                                        <div className="d-flex mb-4">
                                            
                                            <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                            <div className="ms-3">
                                                <div className="fw-bold">Commenter Name</div>
                                                If you're going to lead a space frontier, it has to be government; it'll never be private enterprise. Because the space frontier is dangerous, and it's expensive, and it has unquantified risks.
                                                
                                                <div className="d-flex mt-4">
                                                    <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                                    <div className="ms-3">
                                                        <div className="fw-bold">Commenter Name</div>
                                                        And under those conditions, you cannot establish a capital-market evaluation of that enterprise. You can't get investors.
                                                    </div>
                                                </div>
                                                
                                                <div className="d-flex mt-4">
                                                    <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                                    <div className="ms-3">
                                                        <div className="fw-bold">Commenter Name</div>
                                                        When you put money directly to a problem, it makes a good headline.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex">
                                            <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                            <div className="ms-3">
                                                <div className="fw-bold">Commenter Name</div>
                                                When I look at the universe and all the ways the universe wants to kill us, I find it hard to reconcile that with statements of beneficence.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section> */}

                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
