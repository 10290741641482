import { useState } from 'react';
// import globals from '../config/constant';
import common from '../config/common';
import Loader from './common/Loader';
import { toast } from 'react-toastify';

function Contact() {    
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        mobile_number : '',
        address : '',
        subject: '',
        message: ''
    });
    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const handleSubmit = event => {
        // console.log("Contact-us form data: ");
        // console.log(formData);
        event.preventDefault();
        // fetchData();
       
        setLoading(true);
        common.apiCalling("api/customer/contact-us", "post", formData).then((res) => {            
            setLoading(false);
            // setList({ loading: false });
            // common.print("api res")
            // common.print(res)
            if (res.code === 1) {
                toast.success(res.message);
                setFormData({
                    full_name: '',
                    email: '',
                    mobile_number: '',
                    address: '',
                    subject: '',
                    message: ''
                  });
            } else {
                toast.error(res.message)
            }
          });
        // const fetchData = async () => {
        //     try {
        //         const response = await fetch(url);
        //         const json = await response.json();
        //         // setTitle(json.title);
        //         console.log(json);
        //     } catch (error) {
        //         // setTitle("Some error found");
        //         console.log("error", error);
        //     }
        // };
    }

    // useEffect(() => {
    //     fetchData();
    // }, []);
    return (<div style={{ position: 'relative' }}>
         <Loader loading={loading} />
        <section className="py-5">
            <div className="container px-5">

                <div className="bg-light rounded-3 py-5 px-4 px-md-5 mb-5">
                    <div className="text-center mb-5">
                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-envelope" /></div>
                        <h1 className="fw-bolder">Ask for Support & Services</h1>
                        <p className="lead fw-normal text-muted mb-0">We'd love to hear from you</p>
                    </div>
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-8 col-xl-6">
                            <form id="contactForm" data-sb-form-api-token="API_TOKEN" onSubmit={handleSubmit}>

                                <div className="form-floating mb-3">
                                    <input className="form-control" name='full_name' id="full_name" type="text" placeholder="Enter your full_name..." data-sb-validations="required" value={formData.full_name} onChange={handleChange} required/>
                                    <label htmlFor="full_name">Full name</label>
                                    <div className="invalid-feedback" data-sb-feedback="name:required">A Full name is required.</div>
                                </div>

                                <div className="form-floating mb-3">
                                    <input className="form-control" name='email' id="email" type="email" placeholder="name@example.com" data-sb-validations="required,email" value={formData.email} onChange={handleChange} required/>
                                    <label htmlFor="email">Email address</label>
                                    <div className="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                    <div className="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                                </div>

                                <div className="form-floating mb-3">
                                    <input className="form-control" type="number" name="mobile_number" id="mobile_number" placeholder="(123) 456-7890" data-sb-validations="required" value={formData.mobile_number} onChange={handleChange} required/>
                                    <label htmlFor="mobile_number">Mobile number</label>
                                    <div className="invalid-feedback" data-sb-feedback="mobile_number:required">A Mobile number is required.</div>
                                </div>

                                <div className="form-floating mb-3">
                                    <input className="form-control" name="address" id="address" type="text" placeholder="Building-4, Retri, South lat, NewYork" data-sb-validations="required" value={formData.address} onChange={handleChange} required/>
                                    <label htmlFor="address">Address</label>
                                    <div className="invalid-feedback" data-sb-feedback="address:required">A Address is required.</div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input className="form-control" name="subject" id="subject" type="text" placeholder="Building-4, Retri, South lat, NewYork" data-sb-validations="required" value={formData.subject} onChange={handleChange} required/>
                                    <label htmlFor="subject">Subject</label>
                                    <div className="invalid-feedback" data-sb-feedback="subject:required">A Subject is required.</div>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea className="form-control" name="message" id="message" type="text" placeholder="Enter your message here..." style={{ height: '10rem' }} data-sb-validations="required" value={formData.message} onChange={handleChange} required/>
                                    <label htmlFor="message">Message</label>
                                    <div className="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                                </div>
                                {/* <div className="d-none" id="submitSuccessMessage">
                                    <div className="text-center mb-3">
                                        <div className="fw-bolder">Form submission successful!</div>
                                        To activate this form, sign up at
                                        <br />
                                        <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                                    </div>
                                </div> */}
                                <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>

                                <div className="d-grid"><button className="btn btn-primary btn-lg" id="submitButton" type="submit">Submit</button></div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row gx-5 row-cols-2 row-cols-lg-4 py-5">
                    <div className="col-4 col-lg-4">
                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-chat-dots" /></div>
                        <div className="h5 mb-2">Chat with us</div>
                        <p className="text-muted mb-0">Chat live with one of our support specialists.</p>
                    </div>

                    {/* <div className="col">
                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-people" /></div>
                        <div className="h5">Ask the community</div>
                        <p className="text-muted mb-0">Explore our community forums and communicate with other users.</p>
                    </div> */}

                    <div className="col-4 col-lg-4">
                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-question-circle" /></div>
                        <div className="h5">Support center</div>
                        <p className="text-muted mb-0">Building 1085 pump House, NH19, Nagpur road, colony, Chandangaon, Chhindwara, Madhya Pradesh 480001.</p>
                    </div>
                    <a href="tel:+919406746288"> <div className="col-4 col-lg-4">
                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-telephone" /></div>
                        <div className="h5">Call us</div>
                        <p className="text-muted mb-0">+919406746288.</p>
                    </div>
                    </a>
                </div>
            </div>
        </section>

    </div>);
}

export default Contact;