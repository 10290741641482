import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import Globals from '../../config/constant';
import GetTime from './GetTime';
import "./../../css/ChatPopup.css";
import common from '../../config/common';
import GetUserInfo from './GetUserInfo';

const ChatPopup = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const socketURL = Globals.is_production ? Globals.LIVE_SOCKET_URL : Globals.LOCAL_SOCKET_URL;
  const [socket, setSocket] = useState(null);
  const [isChatOpen, setChatOpen] = useState(false);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [showChat, setShowChat] = useState(false);


  useEffect(() => {
    const newSocket = io(`${socketURL}?user_id=1`);
    setSocket(newSocket);

    newSocket.on('send_message', (message) => {
      // Append received message to the existing messages
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      newSocket.disconnect();
      newSocket.off();
    };
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    // Check if all required inputs are filled
    if (name && email && mobile) {
      setShowChat(true);
    } else {
      alert('Please fill all required fields.');
    }
  };

  const sendMessage = () => {
    if (socket && inputValue.trim() !== '') {
      socket.emit('send_message', inputValue);
      setInputValue('');
    }
  };

  const checkEnter = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const toggleChatPopup = () => {
    setChatOpen((prev) => !prev);
  };

  const closeChatPopup = () => {
    setChatOpen(false);
  };

  const convertToAMPM = (datetime) => {
    const timestamp = Date.parse(datetime);

    if (isNaN(timestamp)) {
      // Check if only time is provided (format: HH:MM:SS)
      const timeRegex = /^(\d{1,2}):(\d{1,2}):(\d{1,2})$/;
      if (timeRegex.test(datetime)) {
        const [hours, minutes, seconds] = datetime.match(timeRegex);
        const time = new Date();
        time.setHours(parseInt(hours, 10));
        time.setMinutes(parseInt(minutes, 10));
        time.setSeconds(parseInt(seconds, 10));
        const options = { hour: 'numeric', minute: 'numeric', hour12: true }; //second: 'numeric',
        return time.toLocaleString('en-US', options);
      }

      return 'Invalid Date or Time Format';
    }

    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
  };
  return (
    <>
      <div className={`chat-icon ${isChatOpen ? 'active' : ''}`} onClick={toggleChatPopup}>
        <i className="fas fa-comment-alt"></i>
      </div>

      {!showChat && isChatOpen && (
        <div className="chat-container">
          <GetUserInfo
            name={name}
            email={email}
            mobile={mobile}
            handleNameChange={handleNameChange}
            handleEmailChange={handleEmailChange}
            handleMobileChange={handleMobileChange}
            handleSubmit={handleSubmit}
            showChat={showChat}

          />
        </div>
      )}
      {isChatOpen && showChat && (
        <div className="chat-container">
          <div>
            <div className="header">
              Welcome to the Chat!
              <button className="close-btn" onClick={closeChatPopup}>
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="messages">
              {messages.slice(0).reverse().map((msg, index) => (
                <div key={index} className={`message ${msg.sender === 'User' ? 'user-message' : 'receiver-message'}`}>
                  {/* <div className="message-time"><GetTime time={msg.time}/></div> */}
                  <div className="message-time">{convertToAMPM(msg.time)}</div>

                  <div className="bubble">{msg.message}</div>
                </div>
              ))}
            </div>

            <div className="input-container fixed-bottom" style={{ position: 'absolute' }}>
              <input
                type="text"
                placeholder="Type your message..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyUp={checkEnter}
              />
              <button className="send-btn" onClick={sendMessage}>
                <i className="fas fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default ChatPopup;
