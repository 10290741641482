// import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

function Header() {
    let location = useLocation();
    // const [title, setTitle] = useState([]);
    // useEffect(() => {
    //     const url = "https://www.utsavitsolution.com/home/";
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(url);
    //             const json = await response.json();
    //             setTitle(json.title);
    //             console.log(json);
    //         } catch (error) {
    //             setTitle("Some error found");
    //             console.log("error", error);
    //         }
    //     };
    //     fetchData();
    // }, [location]);
    return (<>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top" style={{ borderBottom: "1px solid white" }}>
            <div className="container px-5">
                <Link className="navbar-brand" to="/">Utsav IT Solution</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item"><Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} to="/">Home</Link></li>
                        <li className="nav-item"><Link className={`nav-link ${location.pathname === "/about-us" ? "active" : ""}`} to="/about-us">About</Link></li>
                        <li className="nav-item"><Link className={`nav-link ${location.pathname === "/contact-us" ? "active" : ""}`} to="/contact-us">Contact</Link></li>
                        {/* <li className="nav-item"><Link className={`nav-link ${location.pathname==="/"? "active": ""}`} to="/pricing">Pricing</Link></li> */}
                        <li className="nav-item"><Link className={`nav-link ${location.pathname === "/faq" ? "active" : ""}`} to="/faq">FAQ</Link></li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" id="navbarDropdownBlog" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Blog</a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownBlog">
                                <li><Link className="dropdown-item" to="/blogs-listing">Blog Listing</Link></li>
                                {/* <li><a className="dropdown-item" href="/blog-post">Blog Post</a></li> */}
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" id="navbarDropdownPortfolio" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Portfolio</a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownPortfolio">
                                <li><Link className="dropdown-item" to="/portfolio-overview">Portfolio overview</Link></li>
                                <li><Link className="dropdown-item" to="/portfolio-item">Portfolio Item</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        {/* <div style={{height: "1px", color: "white"}} className="fixed-top"></div> */}
       

    </>);
}

export default Header;