import React from 'react';

const GetUserInfo = ({ name, email, mobile, handleNameChange, handleEmailChange, handleMobileChange, handleSubmit, showChat}) => {
    return (
        <div className="getUserInfo">
            <div className="info-message">
                Please provide your information.                 
            </div>
            {!showChat && (
                <form className="needs-validation" noValidate>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            className="form-control"
                            placeholder="Enter your name"
                            value={name}
                            onChange={handleNameChange}
                            required
                        />
                        <div className="invalid-feedback">Please enter your name.</div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                        />
                        <div className="invalid-feedback">Please enter a valid email address.</div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="mobile" className="form-label">
                            Mobile
                        </label>
                        <input
                            type="tel"
                            id="mobile"
                            className="form-control"
                            placeholder="Enter your mobile number"
                            value={mobile}
                            onChange={handleMobileChange}
                            required
                        />
                        <div className="invalid-feedback">Please enter a valid mobile number.</div>
                    </div>

                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
};

export default GetUserInfo;
