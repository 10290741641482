import React, { useState, useEffect } from 'react';
import common from '../config/common';
import Loader from './common/Loader';
import { Link } from "react-router-dom";

export default function BlogsListing() {

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0); // added state to keep track of page number
    const [hasMore, setHasMore] = useState(true); // added state to check if there are more blogs to load

    useEffect(() => {
        setLoading(true);
        // alert()
        common.apiCalling("api/blogs/blogs-listing", "post", { page, order_by: 'DESC' }).then((res) => {
            setLoading(false);
            // setList({ loading: false });
            common.print("API RES: ");
            common.print(res);
            if (res.code === 1) {
                setBlogs(res.data.data);
                setHasMore(true);
                // toast.success(res.message);             
            } else {
                // toast.error(res.message)
                // setBlogs([]);
                setHasMore(false);
            }
        });
    }, [page])

    useEffect(() => {
        function handleScroll() {
            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight && hasMore) {
                setPage(prevPage => prevPage + 1); // increment page number when user reaches the bottom of the page
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore]); // added hasMore as a dependency to update the event listener when there are more blogs to load

    return (
        <>
            <Loader loading={loading} />
            <div className="container mt-5"> {/* add top margin here */}
                <div className="row">
                    {blogs.map(blog => (
                        <div className="col-lg-4 mb-5" key={blog.blog_id} >
                            <Link to={`/blog-details/${blog.blog_id}`} className="card h-100 shadow border-0 text-decoration-none">
                                <img className="card-img-top" style={{
                                    backgroundColor: 'powderblue',
                                }} src={blog.blog_image} alt="..." />
                                <div className="card-body p-4">
                                    <div className="badge bg-primary bg-gradient rounded-pill mb-2">{blog.category}</div>
                                    <h5 className="card-title mb-3">{blog.title}</h5>
                                    <p className="card-text mb-0">{blog.description}</p>
                                </div>
                                <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div className="d-flex align-items-end justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle me-3" src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..." />
                                            <div className="small">
                                                <div className="fw-bold">{blog.written_by}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
