import React, { useState, useEffect } from 'react';
import common from '../config/common';
import Loader from './common/Loader';
import { Link } from "react-router-dom";
function Home() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        common.apiCalling("api/blogs/blogs-listing", "post", { page: 0, order_by: 'DESC' }).then((res) => {
            setLoading(false);
            // setList({ loading: false });
            common.print("api res")
            common.print(res)
            if (res.code === 1) {
                setBlogs(res.data.data)
                // toast.success(res.message);             
            } else {
                // toast.error(res.message)
                setBlogs([])
            }
        });
    }, [])
    return (
        <>
            <Loader loading={loading} />
            <div>
                <header className="bg-dark py-5">
                    <div className="container px-5">
                        <div className="row gx-5 align-items-center justify-content-center">
                            <div className="col-lg-8 col-xl-7 col-xxl-6">
                                <div className="my-5 text-center text-xl-start">
                                    <h1 className="display-5 fw-bolder text-white mb-2">Comprehensive IT Technical Infrastructure Services for Enhanced Efficiency</h1>
                                    <p className="lead fw-normal text-white-50 mb-4">We specializes in delivering a comprehensive range of IT technical infrastructure services to clients and partners. We offer online and offline technical support for a variety of devices including routers, switches, firewalls, servers, and desktops. Our team of skilled professionals provides support for various industries such as banking, petrol pumps, hospitals, call centers, retail shops, malls, and more. </p>
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                        <a className="btn btn-primary btn-lg px-4 me-sm-3" href="#features">Get Started</a>
                                        <a className="btn btn-outline-light btn-lg px-4" href="#!">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img className="img-fluid rounded-3 my-5" src="https://dummyimage.com/600x400/343a40/6c757d" alt="..." /></div> */}
                            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img className="img-fluid rounded-3 my-5" src="/assets/images/home-page-image.jpg" alt="...homeImage" /></div>
                        </div>
                    </div>
                </header>

                <section className="py-5" id="features">
                    <div className="container px-5 my-5">
                        <div className="row gx-5">
                            {/* <div className="col-lg-4 mb-5 mb-lg-0"><h2 className="fw-bolder mb-0">Maximize network performance, minimize downtime. Contact us now.</h2></div> */}
                            <div className="col-lg-4 mb-5 mb-lg-0"><h2 className="fw-bolder mb-0">Optimize your network today.</h2></div>

                            <div className="col-lg-8">
                                <div className="row gx-5 row-cols-1 row-cols-md-2">
                                    <div className="col mb-5 h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-hdd-network" /></div>
                                        <h2 className="h5">Network devices & security</h2>
                                        <p className="mb-0">Essential for success,
                                            Business goals alignment,
                                            End-user devices,
                                            Network device implementation,
                                            Network security management
                                        </p>
                                    </div>
                                    <div className="col mb-5 h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-clipboard-plus" /></div>
                                        <h2 className="h5">Tech care Services</h2>
                                        <p className="mb-0">Software and hardware,
                                            Daily operations & maintenance,
                                            Network management services,
                                            Planning and design,
                                            LAN, WAN, Security,
                                            Service level management
                                        </p>
                                    </div>
                                    <div className="col mb-5 mb-md-0 h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-globe2" /></div>
                                        <h2 className="h5">Network Integration</h2>
                                        <p className="mb-0">Network planning & design,
                                            Network setup & configuration,
                                            Network security,
                                            Network performance optimization,
                                            Network maintenance & support</p>
                                    </div>
                                    <div className="col h-100">
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-hdd-rack-fill" /></div>
                                        <h2 className="h5"> Yearly Maintenance Services</h2>
                                        <p className="mb-0">IT Infrastructure support,
                                            Computer hardware maintenance,
                                            Networking and security,
                                            CCTV and printers,
                                            IT device support
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container px-5 my-5">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-8 col-xl-6">
                                <div className="text-center">
                                    <h2 className="fw-bolder">Experience & Services</h2>
                                    <p className="lead fw-normal text-muted mb-5">You'll get our experience on the technology in the blogs.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-5">
                            {/* {blogs.map(blog => (

                                <div className="col-lg-4 mb-5" key={blog.blog_id}>
                                    <Link to={`/blog-details/${blog.blog_id}`} >
                                        <div className="card h-100 shadow border-0">
                                            <img className="col-lg-4 mb-5 card-img-top" style={{
                                                // objectFit: 'cover',
                                                backgroundColor: 'powderblue',
                                            }} src={blog.blog_image} alt="..." />
                                            <div className="card-body p-4">
                                                <div className="badge bg-primary bg-gradient rounded-pill mb-2">{blog.category}</div>
                                                <a className="text-decoration-none link-dark stretched-link" href="#!"><h5 className="card-title mb-3">{blog.title}</h5></a>
                                                <p className="card-text mb-0"> {blog.description}</p>
                                            </div>
                                            <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                                <div className="d-flex align-items-end justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <img className="rounded-circle me-3" src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..." />
                                                        <div className="small">
                                                            <div className="fw-bold">{blog.written_by}</div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            ))} */}

                            {blogs.map(blog => (
                                <div className="col-lg-4 mb-5" key={blog.blog_id}>
                                    <Link to={`/blog-details/${blog.blog_id}`} className="card h-100 shadow border-0 text-decoration-none">
                                        <img className="card-img-top" style={{
                                            backgroundColor: 'powderblue',
                                        }} src={blog.blog_image} alt="..." />
                                        <div className="card-body p-4">
                                            <div className="badge bg-primary bg-gradient rounded-pill mb-2">{blog.category}</div>
                                            <h5 className="card-title mb-3">{blog.title}</h5>
                                            <p className="card-text mb-0"> {blog.description}</p>
                                        </div>
                                        <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                            <div className="d-flex align-items-end justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <img className="rounded-circle me-3" src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..." />
                                                    <div className="small">
                                                        <div className="fw-bold">{blog.written_by}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                            <div className="text-end">
                                <Link to="/blogs-listing" style={{ color: 'black', textDecoration: 'none' }} onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}> ...See more blogs</Link>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    );
}

export default Home;